<template>
  <div class="full-line flex-row" style="background-color: white; padding: 10px">
    <div class="cube-upload" v-if="!allowed">
      <div class="cube-upload-def clear-fix">
        <div class="cube-upload-file cube-upload-file_success" v-for="(file, index) in files" :key="index" @click="fileClick(file)">
          <div class="cube-upload-file-def" :style="'background-image: url('+file.url+');'">
            <div class="cube-upload-file-state cube-upload-file_stat">
              <span class="cube-upload-file-progress" style="font-size: 12px;">{{file.name}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <cube-upload v-else v-model="files" :action="baseURL+'api/File/UploadAttach'" accept="*" :simultaneous-uploads="1" @files-added="filesAdded" @file-click="fileClick" @file-success="fileSuccess" @file-removed="fileRemoved" />
  </div>
</template>

<script>
import { baseURL } from "@/api";

const wx = window.wx;
export default {
  model: {
    prop: 'attachs',
    event: 'change'
  },
  props: {
    attachs: Array,
    allowed: Boolean,
    baseURL: {
      type: String,
      default: baseURL
    },
  },
  data: function () {
    return {
      files: [],
    };
  },
  methods: {
    filesAdded(files) {
      let hasIgnore = false
      const maxSize = 10 * 1024 * 1024 // 10M
      for (let k in files) {
        const file = files[k]
        if (file.size > maxSize) {
          file.ignore = true
          hasIgnore = true
        }
      }
      hasIgnore && this.$createToast({
        type: 'warn',
        time: 1000,
        txt: '单个附件最大限制10M'
      }).show();
    },
    fileSuccess(file) {
      this.attachs.push({
        id: file.response.data.id,
        pid: file.response.data.pid,
        file_size: file.response.data.file_size,
        file_path: file.response.data.file_path,
        file_name: file.response.data.file_name,
        file_ext: file.response.data.file_ext,
        add_time: file.response.data.add_time,
      });
      this.$emit("change", this.attachs);
    },
    fileRemoved(file) {
      var index = this.attachs.findIndex(item => {
        if (this.baseURL + item.file_path == file.url) {
          return true;
        }
      });
      this.attachs.splice(index, 1);
      this.$emit("change", this.attachs);
    },
    fileClick(file) {
      console.log(file);
      wx.miniProgram.navigateTo({
        url: '/pages/preview/preview?param=' + btoa(file.url)
      });
    },
    fileLoad() {
      this.files = this.attachs.map(file => {
        return {
          name: file.file_name,
          size: file.file_size,
          url: this.baseURL + file.file_path,
          status: "success",
          progress: 1,
          response: {
            data: {
              id: file.id,
              pid: file.pid,
              file_size: file.file_size,
              file_path: file.file_path,
              file_name: file.file_name,
              file_ext: file.file_ext,
              add_time: file.add_time,
            }
          }
        }
      });
    }
  },
  watch: {
    attachs: function () {
      this.fileLoad();
    }
  },
  mounted() {
    this.fileLoad();
  }
}
</script>

<style scoped>
</style>
